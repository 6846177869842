import React, { FunctionComponent } from "react";

import SEO from "../components/Seo";
import { DefaultLayout } from "../components/DefaultLayout";
import Img, { FluidObject } from "gatsby-image";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

type SiblingPage = {
  id: string;
  frontmatter: { title: string };
  fields: {
    slug: string;
  };
};

type ProjectDetailsProps = {
  pageContext: {
    isCreatedByStatefulCreatePages: boolean;
    id: string;
    previous: SiblingPage | null;
    next: SiblingPage | null;
  };
  data: {
    mdx: {
      body: string;
      frontmatter: {
        title: string;
        image?: {
          childImageSharp: {
            fluid: FluidObject;
          };
        };
        collection?: {
          childImageSharp: {
            fluid: FluidObject;
          };
        }[];
      };
    };
  };
};

const ProjectDetails: FunctionComponent<ProjectDetailsProps> = ({ data }) => {
  return (
    <>
      <SEO title={"Projekt Details"} />
      <DefaultLayout>
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
        {data.mdx.frontmatter.collection
          ? data.mdx.frontmatter.collection.map((item, index) => (
              <div
                style={{
                  margin: "2rem 0",
                  width: "100%",
                }}
                key={index}
              >
                <Img
                  fluid={item.childImageSharp.fluid}
                  key={index}
                  alt={`Bild-${index}`}
                  style={{
                    maxHeight: "90vh",
                  }}
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
            ))
          : null}
      </DefaultLayout>
    </>
  );
};

export default ProjectDetails;

export const data = graphql`
  query ProjectQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048) {
              src
              srcSet
              sizes
              aspectRatio
            }
          }
        }
        collection {
          childImageSharp {
            fluid(maxWidth: 768) {
              src
              srcSet
              aspectRatio
            }
          }
        }
      }
      body
    }
  }
`;
